<template>
  <div class="card card-custom card-stretch gutter-b" v-loading="loading">
    <div class="card-header">
      <div class="card-title">
        <h3><router-link :to="{ name: 'canlendar'}">カレンダー</router-link> <span class="custom-li"> > </span> <a href="javascript:void(0)">予約不可の設定</a> </h3>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <div class="container">
        <div class="wrap_detail_ticket_pop">
          <div class="wrap_date_choose" style="postion: relative">
            <button
                class="slick-prev slick-arrow"
                aria-label="Previous"
                type="button"
                style
                @click="setDate(-1)"
            >Previous</button>
            <button
                class="slick-next slick-arrow"
                aria-label="Next"
                type="button"
                style
                @click="setDate(1)"
            >Next</button>
            <div class="tg_lang click-ul-tog tog-rest-time">
              <p>{{this.year}} 年</p>
            </div>
          </div>
        </div>

        <div class="wrap_list_month" v-html="showYear()">
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogRestTime"
        width="40%"
        :title="form.dateFomat"
        class="dia-log-resttime"
        center
        :before-close="handleClose"
    >
              <p class="text-rest-time">予約不可の時間</p>
            <el-form>
              <el-form-item
                  label-width="0px"
                  v-for="(item, index) in arrayTime"
                  :key="index"
                  prop="parent"
              >
                <template>
                  <div class="row">
                    <el-time-select
                        placeholder="hh:mm"
                        v-model="item.start_time"
                        class="time-select"
                        :picker-options="{
              start: '10:00',
              step: '00:30',
              end: '16:00'
              }">
                    </el-time-select>
                    <span class="tilde">~</span>
                    <el-time-select
                        placeholder="hh:mm"
                        v-model="item.end_time"
                        class="time-select"
                        :picker-options="{
                start: '10:00',
                step: '00:30',
                end: '16:00',
                minTime: item.start_time
    }">
                    </el-time-select>
                    <span class="pt-2"><i @click="removeItem(index)" class="fas fa-trash-alt icon-delete"></i></span>
                  </div>

                </template>
                <div>
                  <span class="error" v-if="errorMessage[index + '_index'] && errorMessage[index + '_index']['start_time']">{{errorMessage[index + '_index']['start_time'][0]}}</span>
                </div>

              </el-form-item>
              <div class="icon-add">
                <i v-if="arrayTime.length > 0 " @click.prevent="addNewItem()" class="fas fa-plus-square"></i>
                <i v-else @click.prevent="addNewItem()" class="fas fa-plus-square i-custom"></i>
              </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <div class="button-time text-center">
                 <el-button class="btn-cancel" style="width: 120px;" @click="closeDiaLogRestTime()">閉じる</el-button>
                <el-button style="width: 120px;" type="primary" @click="addRestTime()">保存</el-button>
              </div>
            </span>
    </el-dialog>
  </div>

</template>

<script>
import moment from "moment";
import { GET_DATA_CALENDAR_REST_TIME } from "@/core/services/store/calendar.module";
import { STATUS_CODE } from "@/core/config/constant";
import { ADD_REST_TIME, GET_DATA_BY_DATE} from "@/core/services/store/restTime.module";

import { ENV } from "@/core/config/env";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE;


export default {
  name: "DetailOrder",
  data() {
    return {
      data: {},
      loading: false,
      currentYear: 0,
      year: "",
      firstDay: "",
      lastDay: "",
      dialogRestTime: false,
      form: {
        arrayTime: [],
        date: "",
      },
      arrayTime: [{
        start_time:'',
        end_time:'',
      }],
      arrTimeFromApi: [],
      errorMessage:{},
    };

  },

  async created() {
    await this.setDate(0);
    const dates =  document.getElementsByClassName("bg-grey");
    const dateNoTime =  document.getElementsByClassName("bg-white");
    let self = this;
    Array.from(dates).forEach(date => date.addEventListener('click', function (event) {
      date =  event.target.getAttribute("att-date");
      let format = date.split('-');
      let year = format[format.length -3];
      let month = format[format.length -2];
      let day = format[format.length -1];
      year = year + '年';
      month = month + '月';
      day = day + '日';
      let dateFormat = year + month + day;
      self.form.dateFomat = dateFormat;

      self.openDiaLogRestTime()
      self.getDataByDate(date)
    }));
    Array.from(dateNoTime).forEach(date => date.addEventListener('click', function (event) {
      date = event.target.getAttribute("att-date");
      self.form.date = date;

      let format = date.split('-');
      let year = format[format.length -3];
      let month = format[format.length -2];
      let day = format[format.length -1];
      year = year + '年';
      month = month + '月';
      day = day + '日';
      let dateFormat = year + month + day;
      self.form.dateFomat = dateFormat;
      self.openDiaLogRestTime()

    }));
  },

  methods: {
    async setDate(e) {
      if (e == 1) {
        this.currentYear += 1;
      } else if (e == -1) {
        this.currentYear -= 1;
      } else {
        this.currentYear = 0;
      }
      this.setYear();
      this.showYear();

      await this.getData();
      const dates =  document.getElementsByClassName("bg-grey");
      const dateNoTime =  document.getElementsByClassName("bg-white");
      let self = this;
      Array.from(dates).forEach(date => date.addEventListener('click', function (event) {
        date =  event.target.getAttribute("att-date");
        let format = date.split('-');
        let year = format[format.length -3];
        let month = format[format.length -2];
        let day = format[format.length -1];
        year = year + '年';
        month = month + '月';
        day = day + '日';
        let dateFormat = year + month + day;
        self.form.dateFomat = dateFormat;

        self.openDiaLogRestTime()
        self.getDataByDate(date)
      }));
      Array.from(dateNoTime).forEach(date => date.addEventListener('click', function (event) {
        date = event.target.getAttribute("att-date");
        self.form.date = date;

        let format = date.split('-');
        let year = format[format.length -3];
        let month = format[format.length -2];
        let day = format[format.length -1];
        year = year + '年';
        month = month + '月';
        day = day + '日';
        let dateFormat = year + month + day;
        self.form.dateFomat = dateFormat;
        self.openDiaLogRestTime()

      }));
    },

    setYear() {
      this.year = moment()
          .clone()
          .add(this.currentYear, "y")
          .format("YYYY");

      this.firstDay = moment().add(this.currentYear, 'years').startOf('year').format('YYYY-MM-DD');
      this.lastDay = moment().add(this.currentYear, 'years').endOf('year').format('YYYY-MM-DD');
    },

    calendarMonth(year, month) {
      const previousMonthDays = this.createDaysForPreviousMonth(year, month);
      const nextMonthDays = this.createDaysForNextMonth(year, month);
      const currentMonthDays = this.createDaysForCurrentMonth(
          year,
          month,
          moment(`${year}-${month}-01`).daysInMonth()
      );
      const days = [
        ...previousMonthDays,
        ...currentMonthDays,
        ...nextMonthDays
      ];
      return days;
    },

    funcGetWeekDay(date) {
      return moment(date).weekday();
    },

    createDaysForPreviousMonth(year, month) {
      const firstDayOfTheMonthWeekday = this.funcGetWeekDay(
          this.currentMonthDays(year, month)[0].date
      );

      const previousMonth = moment(`${year}-${month}-01`).subtract(1, "month");

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
          ? firstDayOfTheMonthWeekday - 1
          : 6;

      const previousMonthLastMondayDayOfMonth = moment(
          this.currentMonthDays(year, month)[0].date
      )
          .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
          .date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
          (day, index) => {
            return {
              date: moment(
                  `${previousMonth.year()}-${previousMonth.month() +
                  1}-${previousMonthLastMondayDayOfMonth + index}`
              ).format("YYYY-MM-DD"),
              dayOfMonth: previousMonthLastMondayDayOfMonth + index,
              isCurrentMonth: false
            };
          }
      );
    },

    currentMonthDays(year, month) {
      return this.createDaysForCurrentMonth(
          year,
          month,
          moment(`${year}-${month}-01`).daysInMonth()
      );
    },

    createDaysForCurrentMonth(year, month) {
      return [...Array(this.getNumberOfDaysInMonth(year, month))].map(
          (day, index) => {
            return {
              date: moment(`${year}-${month}-${index + 1}`).format("YYYY-MM-DD"),
              dayOfMonth: index + 1,
              isCurrentMonth: true
            };
          }
      );
    },

    getNumberOfDaysInMonth(year, month) {
      return moment(`${year}-${month}-01`).daysInMonth();
    },

    createDaysForNextMonth(year, month) {
      const lastDayOfTheMonthWeekday = this.funcGetWeekDay(
          `${year}-${month}-${this.currentMonthDays(year, month).length}`
      );

      const nextMonth = moment(`${year}-${month}-01`).add(1, "month");

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
          ? 7 - lastDayOfTheMonthWeekday
          : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: moment(
              `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
          ).format("YYYY-MM-DD"),
          dayOfMonth: index + 1,
          isCurrentMonth: false
        };
      });
    },

    showYear() {
      var now = moment().format('YYYY-MM-DD');
      var html = "";
      for (let k = 1; k <= 12; k++) {
        let dataMonth = this.calendarMonth(this.year, k);
        let numWeek = Math.ceil(dataMonth.length / 7);
        let n = 0;
        html += '<div class="month"><table>'
        html += `<tr><td colspan="7" align="center"><span class="header_month">${this.year} 年 ${k} 月</span></td></tr>`
        html += '<tr align="center"><td><b>月</b></td><td><b>火</b></td><td><b>水</b></td><td><b>木</b></td><td><b>金</b></td><td><b>土</b></td><td><b>日</b></td></tr>'
        for (let i = 0; i < numWeek; i++) {
          html += '<tr align="center">'
          for (let j = n; j < dataMonth.length; j++) {
            let obj = this.data[dataMonth[j].date];
            let date = moment(dataMonth[j].date, "YYYY-MM-DD").format('D')
            if ((j + 1) % 7 === 0) {
              if (!dataMonth[j].isCurrentMonth) {
                html += `<td class="bg-grey-cus"><br/></td>`;
              } else {
                if (dataMonth[j].date < now) {
                  html += `<td class="bg-grey-cus">${date}<br/></td>`;
                } else {
                  if (obj) {
                    html += `<td class="bg-grey" att-date="${dataMonth[j].date}">${date}<br/></td>`;
                  } else {
                    html += `<td class="bg-white" att-date="${dataMonth[j].date}">${date}<br/></td>`;
                  }
                }
              }
              n = (i + 1) * 7
              break;
            } else {
              if (!dataMonth[j].isCurrentMonth) {
                html += `<td class="bg-grey-cus"><br/></td>`;
              } else {
                if (dataMonth[j].date < now) {
                  html += `<td class="bg-grey-cus">${date}<br/></td>`;
                } else {
                  if (obj) {
                    html += `<td class="bg-grey" att-date="${dataMonth[j].date}">${date}<br/></td>`;
                  } else {
                    html += `<td class="bg-white" att-date="${dataMonth[j].date}">${date}<br/></td>`;
                  }
                }
              }
            }
          }
          html += '</tr>'
        }
        html += '</table></div>'
      }

      return html;
    },

    async getData() {
      this.loading = true;
      let params = this.serialize({
        start_date: this.firstDay,
        end_date: this.lastDay
      });
      let data = await this.$store.dispatch(GET_DATA_CALENDAR_REST_TIME, params);
      if (data.code === SUCCESS) {
        this.data = data.data;
      }
      this.loading = false;
    },

    dataDefault(){
      return {
        start_time: '',
        end_time: '',
      }
    },

    addNewItem(){
      this.errorMessage = {};
      this.arrayTime.push(this.dataDefault());
    },

    removeItem(index){
      this.errorMessage = {};
      this.arrayTime.splice(index, 1);

    },

    async addRestTime(){
      let self = this;
      this.form.arrayTime = [];

      this.form.arrayTime = this.arrayTime.map((arrayTime, index) => {
        arrayTime['index'] = index;
        return arrayTime;
      });
      const data = await this.$store.dispatch(ADD_REST_TIME, this.form);
      if (data.code === STATUS_CODE.SUCCESS) {
        this.notifySuccess(this.$t("success"));
        this.dialogRestTime = false;
        //this.$router.go(0);
        this.errorMessage = {};
        await this.getData();
        const dates =  document.getElementsByClassName("bg-grey");
        const dateNoTime =  document.getElementsByClassName("bg-white");
        let self = this;
        Array.from(dates).forEach(date => date.addEventListener('click', function (event) {
          date =  event.target.getAttribute("att-date");
          let format = date.split('-');
          let year = format[format.length -3];
          let month = format[format.length -2];
          let day = format[format.length -1];
          year = year + '年';
          month = month + '月';
          day = day + '日';
          let dateFormat = year + month + day;
          self.form.dateFomat = dateFormat;

          self.openDiaLogRestTime()
          self.getDataByDate(date)
        }));
        Array.from(dateNoTime).forEach(date => date.addEventListener('click', function (event) {
          date = event.target.getAttribute("att-date");
          self.form.date = date;

          let format = date.split('-');
          let year = format[format.length -3];
          let month = format[format.length -2];
          let day = format[format.length -1];
          year = year + '年';
          month = month + '月';
          day = day + '日';
          let dateFormat = year + month + day;
          self.form.dateFomat = dateFormat;
          self.openDiaLogRestTime()

        }));

      }
      else if(data.code === STATUS_CODE.BAD_REQUEST) {
        let messageError = {};
        let self = this;
        Object.keys(data.data).forEach((item)=>{
          let arrItem = item.split('.')
          let index = Number(arrItem[1]);

          if(!messageError[index + '_index']){
            messageError[index + '_index'] = {}
          }

          messageError[index + '_index'][arrItem[2]] = []
          messageError[index + '_index'][arrItem[2]].push(data.data[item][0])

        });

        this.errorMessage =  messageError;
      }
      else if(data.code === STATUS_CODE.ERROR_SERVER){
        this.notifyError(this.$t("errorServer"));

      }
      this.loading = false;

    },

    async getDataByDate(value){
      this.form.arrayTime = [];
      this.form.date = [];
      this.form.type = [];

      const data = await this.$store.dispatch(GET_DATA_BY_DATE, {date: value });
      if (data.code === STATUS_CODE.SUCCESS) {

        this.form.date = data.data[0].date;
        this.arrayTime = data.data;
        this.arrTimeFromApi = JSON.parse(JSON.stringify(this.arrayTime))
      }
      this.loading = false;

    },
    openDiaLogRestTime(){
      this.form.arrayTime = [];
      this.form.type = [];
      this.dialogRestTime = true;
    },
    closeDiaLogRestTime(){
      this.errorMessage = {};
      this.dialogRestTime = false;
      this.arrayTime = [{
        start_time:'',
        end_time:'',
      }]
    },
    handleClose(){
      this.errorMessage = {};
      this.dialogRestTime = false;
      this.arrayTime = [{
        start_time:'',
        end_time:'',
      }]
    }
  }

};
</script>
<style>
.tog-rest-time {
  text-align: center;
  padding: 0px !important;
  border: none !important;
}
.tog-rest-time::before {
  content: none !important;
}
.tog-rest-time p {
  font-size: 25px;
}

.wrap_list_month{
  display: flex;
  flex-wrap: wrap;
}

.month{
  flex: 1 0 40%; /* explanation below */
  margin: 5px 30px;
  padding: 10px;
  height: 250px;
  border: 1px solid black;
}

.month table{
  width: 100%;
  height: 100%;
}
.header_month {
  font-size: 18px;
}
.month table tr td {
  width: 14%;
}
.bg-grey {
  background: grey;
  border: 1px solid white;
}
.bg-grey-cus {
  background: #f3f3f3;
  border: 1px solid white;
}
.el-icon-close{
  display: none;
}
.icon-delete{
  color: red;
  font-size: 20px;
  margin-left: 20px;
}
.icon-add i{
  color: #3699ff;
  font-size: 30px;
  padding-top: 20px;
  margin-left: -12px;
}
.btn-cancel{
  background-color: #AAAAAA;
  color: white;
  border-color: #AAAAAA;
}
.btn-cancel:hover{
  background-color: #AAAAAA;
  color: white;
  border-color: #AAAAAA;
}
.time-select{
  width: 150px !important;
}
.dia-log-resttime .el-dialog__body{
  display: flex !important;
  justify-content: center !important;
}
.dia-log-resttime .el-dialog__header{
  background-color: #169BD5;
  color: white;
}
.dia-log-resttime .el-dialog__header span{
  color: white;
}
.error{
  color: red;
  font-size: 11px;
}
.calendar ul {
  list-style: none;
}
.calendar ul li {
  float: left;
  margin-left: 10px;
}
.custom-li {
  color: #AAAAAA;
}
p {
  margin-bottom: 0rem;
}
/*.wrap_detail_ticket_pop {*/
/*  margin: 20px 540px 10px auto;*/
/*  max-width: 200px;*/
/*}*/

.el-scrollbar__bar{
  opacity: 1 !important;
}
.tilde{
  padding:5px 10px 0px 10px;
}
.i-custom{
  margin-left: -180px !important;
}
.text-rest-time{
  position: absolute;
  left: 50px;
  top: 75px;
}
.el-form{
  margin-top: 35px;
}
</style>